window.DFI === undefined ? window.DFI = {} : DFI = window.DFI;

(function() {

	window.DFI["DFI_configuration"] = {} ;
	let DFI = window.DFI;
	let idTag = window.idTagDelio || window.DFI.idTagDelio;

	/**
	 * Devuelve un parámetro de la URL
	 */
	DFI.getUrlVar = function(variable, url) {
		var href = url || window.location.search;
		var i, pair;
		var query = href.split("?")[1];
		var vars = query ? query.split("&") : [];

		for (let i = 0; i < vars.length; i++) {
			pair = vars[i].split("=");
			if (pair[0] == variable && pair[1] !== "") return pair[1];
		}

		return "";
	};

	// Entorno
	let env = "c";
	let envs = ["c", "i", "d"];
	if (DFI.getUrlVar("wm_env") != "" || sessionStorage.wm_env != "") {
		env = DFI.getUrlVar("wm_env") || sessionStorage.wm_env;
	}

	if (envs.indexOf(env) === -1) {
		env = "c";
	} else {
		sessionStorage.wm_env = env;
	}

	DFI.path = "https://t.womtp.com/slider/" + env + "/delio-lm/dfi/";

	let inits = document.querySelectorAll('head script[src*="dfi/init.js"]');
	let current = inits[inits.length - 1].src;
	if (current.indexOf(`/${env}/`) == -1) {
		// Detener script actual e injectar el correspondiente al entorno
		var script = document.createElement("script");
		script.type = "text/javascript";
		script.src = "https://t.womtp.com/slider/" + env + "/delio-lm/dfi/init.js";
		var head = document.getElementsByTagName("head")[0];
		head.appendChild(script);

		return;
	}

	DFI.global = {
		// Paths
		support: DFI.path + "support/",
		// Styles
		dmvStyles: DFI.path + "assets/stylesheets/dmv.css",
		// Scripts
		dal: "https://ws.walmeric.com/static/dft/dist/v1/dal.js",
		dmv: "https://ws.walmeric.com/static/dft/dist/v1/dmv.js",
		dsa: "https://ws.walmeric.com/static/dft/dist/v1/dsa.js",
		drp: "https://ws.walmeric.com/static/dft/dist/v2/drp.js",
		// Delio Script Paths
		dcl: "https://ws.walmeric.com/static/dft/dist/v2/dcl.js?idTag=",
		womtpPixels: "https://t.womtp.com/js/otg.min.js?idtag=",
		dsaImage: DFI.path + "/assets/img/agente_amarillo.png",
		dsaClose: DFI.path + "/assets/img/btn_close_red_01.png",
		// Ids
		idService: "3c9fba54fc213a29e5f9f508f14a171e",
		idTagDelioClient: "29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a46e3e3b0ef69f41f96d40a4dd87f979fdcf4ac51fa742bf0b334c8e84fe6171a45244585249a48705aa5198de2e2166d2fab7ca310e1b1e71740a4b25b2e67b86ec5be3c8d75a0f0303fb5ee41b64ce1'",
		tagConversion: "78a97f28d2b8e251f79a79cdbbdffef1",
		tagVisit: "f4828d48fb1fd21b7bdb16e5d94b073d",
		tagGet: "https://ws.walmeric.com/provision/wsclient/delioClientLeadId_get.html?idTag=",
		operatorHistory: "https://t.womtp.com/slider/d/delio-lm/ws/people-call.php?phone=",
		requestRingPoolNumber:"https://t.womtp.com/adcd/v1/?loopc=0&",
		sendRingPoolLabels:"https://ws.walmeric.com/provision/wsclient/sendLabelsRingPool.html?format=jsonp&",
		//Chat desarrollo
		//chat: 'https://t.womtp.com/sio-w/dev/chat.js',
		//chat producción,
		chat: 'https://t.womtp.com/sio-w/chat.js',
		//Chat integración o producción = 'int' es integración "" es producción
		chatEnviroment: '',
		scoringVisitoId: 'https://ws.walmeric.com/provision/wsscoring/calculate_visitor_scoring.html',
		scoringLeadId: 'https://ws.walmeric.com/provision/wsscoring/calculate_lead_scoring.html',
		updateVisitLabelByVisitorId: 'https://ws.walmeric.com/provision/visitsLabel/updateVisitLabelByVisitorId.html'
	};

	// Provision: dependiente de entorno
	if (env === "i") {
		DFI.global.provision = "//integration.delio-lm.com/provision/";
	} else {
		DFI.global.provision = "https://ws.walmeric.com/provision/";
	}

	if (document.location.href.indexOf('wm_chat=dev') !== -1) {
        DFI.global.chat = "https://t.womtp.com/sio-w/dev/chat.js";
    }
	
	/**
	 * Carga con JSONP las configs del cliente
	 * Tras ello, elimina la etiqueta del DOM.
	 *
	 */
	DFI.loadConfigs = function(idTag) {
		var script = document.createElement("script");
		script.type = "text/javascript";
		script.src = DFI.global.provision + 'script/script_get.html?format=jsonp&idTag=' + idTag + '&callback=DFI.launchClient';
		var head = document.getElementsByTagName("head")[0];
		head.appendChild(script);
	};

	/**
	 * Callback para el método loadConfigs. Una vez están las configs del cliente cargadas,
	 * establece el entorno y la versión a la que apuntaremos.
	 */
	DFI.launchClient = function(data) {
		let DFI = window.DFI;

		DFI.DFI_configuration = data;
		let version = DFI.DFI_configuration.version;		
		// Lanzar script para la versión del cliente
		var script = document.createElement("script");
		script.type = "text/javascript";


		// Elección de la versión del archivo init
		let old_versions = ["v3"];
		if (old_versions.indexOf(version) > -1) {
			script.src = "https://t.womtp.com/slider/" + env + "/delio-lm/dfi/init_old.js";
		} else {
			script.src = "https://t.womtp.com/slider/" + env + "/delio-lm/dfi/" + version + "/client.js";
		}

		var head = document.getElementsByTagName("head")[0];
		head.appendChild(script);
	};

	/**
	 * Carga un script y lo inyecta en el head
	 *
	 */
	DFI.loadScriptBefore = function(url, fn, docScope) {
		var doc = docScope || document;
		var head = doc.head || doc.getElementsByTagName("head")[0];
		var script = doc.createElement("script");
		script.src = url;
		script.type = "text/javascript";

		if (fn) script.addEventListener('load', fn);

		head.appendChild(script);
	};

	DFI.loadConfigs(idTag);
})();